
<template>
    <div class="trackit">
        <van-form class="sale_form" @submit="onSubmit">
            <van-row type="flex" class="border_bottom" justify="center">
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.game"
                        label="选择游戏"
                        placeholder="请选择"
                        :left-icon="form.gameicon"
                        @click="serverFn(0)"
                    />
                    <!-- form.gamePicker = true -->
                    <van-popup v-model="form.gamePicker" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="channelName"
                            :columns="form.games"
                            @confirm="popConfirm"
                            @cancel="form.gamePicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.server"
                        label="选择区服"
                        placeholder="请选择"
                        :left-icon="form.servericon"
                        @click="serverFn(1)"
                    />
                    <van-popup v-model="form.serverPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="server_name"
                            :columns="form.servers"
                            @confirm="popConfirm"
                            @cancel="form.serverPicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.role"
                        label="选择角色"
                        placeholder="请选择"
                        :left-icon="form.roleicon"
                        @click="serverFn(2)"
                    />
                    <van-popup v-model="form.rolePicker" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="chrname"
                            :columns="form.roles"
                            @confirm="popConfirm"
                            @cancel="form.rolePicker = false"
                        />
                    </van-popup>
                </van-col>
            </van-row>
            <van-row type="flex" class="border_bottom" justify="center">
                <van-col span="21">
                    <van-field v-model="form.content" label="" placeholder="请填写申诉内容(必填)" />
                </van-col>
            </van-row>
            <div class="color_text orange">*提供详细申诉描述，可帮助工作人员更快进行问题处理。处理结果将会发送站内信通知</div>
            <router-link to="/appealRecord"><div class="color_text blue">封号申诉记录</div></router-link>
            <van-row type="flex" justify="center" class="van_rule">
                <van-col span="21" class="sumbit_btn">
                    <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">提交申诉</van-button>
                </van-col>
            </van-row>
        </van-form>
    </div>
</template>
<script>
// import Top from '@/components/top'

export default {
    name: 'Trackit',
    components: {
    },
    data() {
        return {
            active: 0,
            value:'',
            form: {
                game: '',
                games: [],
                channel_id:'',
                gamePicker: false,
                gameicon: require('../../assets/images/sale_icon1.png'),
                server: '',
                servericon: require('../../assets/images/sale_icon2.png'),
                serverPicker: false,
                servers: [
                    {
                        text: '仙境001',
                        id: '1001'
                    },{
                        text: '仙境002',
                        id: '1002'
                    },{
                        text: '仙境003',
                        id: '1003'
                    }
                ],
                role: '',
                roleicon: require('../../assets/images/sale_icon3.png'),
                rolePicker: false,
                roles: [
                    {
                        text: '战士',
                        id: '1001'
                    },{
                        text: '法师',
                        id: '1002'
                    },{
                        text: '道士',
                        id: '1003'
                    }
                ],
                content:''
            },
            upData:{}
        }
    },
    
    created(){
        if(!localStorage.getItem('token')){
            return false;
        }
        // 获取游戏渠道
        let data = {
            onsale_type:1 
        }
        this.$api.helper.getChannel(data).then((res)=>{
            if (sessionStorage.getItem("type")==='24') {
                let arr_item = []
                res.data.forEach((item,index)=>{
                if(item.channelId==24){
                    // console.log(res.data[index])
                    // res.data = []
                    // res.data.push(res.data[index])
                    arr_item.push(res.data[index])
                }
                })
                res.data = arr_item
                // console.log(res.data)
            }else{
                res.data.forEach((item,index)=>{
                if(item.channelId==24){
                    res.data.splice(index,1)
                }
                })
            }
            this.form.games = res.data;
            // if( JSON.stringify(this.$route.params) !== "{}"){
            if(false){
                let obj = this.$route.params;
                // console.log(obj)
                this.form.content = obj.content;
                this.form.games.forEach(item=>{
                    if(item.channelId==obj.channel_id){
                        let data = {
                            channel_id:obj.channel_id
                        }
                        this.upData.channel_id = obj.channel_id;
                        this.upData.server_id = obj.server_id;
                        // 
                        this.form.channel_id = obj.channel_id;
                        // 获取区服
                        this.$api.helper.getServerList(data).then((res)=>{
                            this.form.servers = res.data;
                            this.form.game = obj.channel_name;
                            this.form.servers.forEach(item=>{
                                if(item.server_id==obj.server_id){
                                    let data = {
                                        channel_id:this.form.channel_id,
                                        server_id:item.server_id
                                    }
                                    this.form.server = item.server_name;
                                    this.upData.regione_id = item.regione_id;
                                    // 获取角色数据
                                    this.$api.helper.getPlayerList(data).then((res)=>{
                                        this.form.roles = res.data;
                                        this.form.roles.forEach(item=>{
                                            if(item.seedname==obj.seedname){
                                                this.upData.seedname = item.seedname;
                                                this.upData.chrname = item.chrname;
                                                this.form.role = item.chrname;
                                            }
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            }
        })
    },
    methods: {
        // 验证时候登录
        isToken(){
            let token = localStorage.getItem('token');
            if(!token){
                this.$router.push({
                    name: 'Login',
                });
            }
        },
        // 选择区服角色
        serverFn(nb){
            this.isToken();
            if(!localStorage.getItem('token')){
                return false;
            }
            if(nb==0){
                this.form.gamePicker = true;
            }else if(nb==1){
                // 选择区服
                if(this.form.game){
                    this.form.serverPicker = true;
                }else{
                    this.$toast({
                        message: '请先选择项目，再选区服',
                        duration: 1500
                    });

                }
            }else if(nb==2){
                // 选择角色
                if(this.form.server){
                    this.form.rolePicker = true;
                }else{
                    this.$toast({
                        message: '请先选择区服，再选角色',
                        duration: 1500
                    });

                }
            }
            
            
        },
        //tab切换
        tabsClick(name) {
            //console.log(name);
        },
        //返回一个特定的 DOM 节点，作为挂载的父节点,挂在气泡框
        getContainer() {
            return document.querySelector('.van_rule');
        },
        //选择游戏确认
        popConfirm(val, index) {
            let str;
            if(this.form.gamePicker){
                str = 'game';
                if(this.form.games.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                let data = {
                    channel_id:val.channelId
                }
                this.upData.channel_id = val.channelId;
                this.form.channel_id = val.channelId;
                this.$api.helper.getServerList(data).then((res)=>{
                    // this.form.servers = res.data;
                    this.form.servers = [];
                    for (var key in res.data) {
                        this.form.servers.push({
                            server_name: res.data[key].server_name,
                            server_id: res.data[key].server_id,
                            regione_id:res.data[key].regione_id
                        });
                    }
                })
                this.form[str] = val.channelName;
                // 清空值
                this.form.server = '';
                this.form.role = '';
                this.form.content = '';
            }else if(this.form.serverPicker){
                str = 'server';
                if(this.form.servers.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                let data = {
                    channel_id:this.form.channel_id,
                    server_id:val.server_id,
                    type: 0
                }
                this.upData.regione_id = val.regione_id;
                // this.upData.server_id = val.server_id;
                this.form[str] = val.server_name;
                this.$api.helper.getPlayerList(data).then((res)=>{
                    this.form.roles = res.data;
                })
                // 清空值
                this.form.role = '';
                this.form.content = '';
            }else if(this.form.rolePicker){
                str = 'role';
                if(this.form.roles.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                this.upData.seedname = val.seedname;
                this.upData.server_id = val.server_id;
                this.upData.chrname = val.chrname;
                this.form[str] = val.chrname;
                // 清空值
                this.form.content = '';
            }
            // this.form[str] = val.text;
            this.form[str+'Picker'] = false;
        },
        //提交申请
        onSubmit() {
            this.isToken();
            if(!localStorage.getItem('token')){
                return false;
            }
            if(!this.form.role){
                this.$toast({
                    message: '请选择角色',
                    duration: 1500
                });
            }else if(!this.form.content){
                this.$toast({
                    message: '请填写申诉原因',
                    duration: 1500
                });
            }else{
                this.upData.content =  this.form.content;
                this.upData.user_name = localStorage.getItem('username');
                this.$api.helper.addAccountAppeal(this.upData).then((res)=>{
                    if(res.data.data == 0 || res.code !=200){
                        this.$toast({
                            message: res.msg,
                            duration: 1500
                        });
                    }else{
                        this.$toast({
                            message: '申请已提交',
                            duration: 1500
                        });
                        this.form.game = '';
                        this.form.server = '';
                        this.form.role = '';
                        this.form.content = '';
                        Object.keys(this.upData).forEach(key => this.upData[key] = '');
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.sale_form{
    padding: 4% 0;
}
.sale_form .van-col{
    margin-bottom: 3%;
}
.sale_form .van-cell{
    background-color: #efefef;
    border-radius: 3px;
    padding: 10px 2% 10px 15px;
}
.sale_form .van-field__label{
    color: #313131;
}
.sale_form .empower .van-field__label{
    width: 13em;
}
.border_bottom{
    border-bottom: 1px solid #dedede;
    margin-bottom: 3%;
}
.color_text{
    font: .6875rem/1.46875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    width: 87.5%;
    margin: 0 auto;
    text-align:left;
}
.orange{
    color: #808080 !important;
    padding-top: .5625rem;
    /* font: ; */
}
.blue{
    color: #ff4e00;
    text-decoration: underline;
    padding-bottom: 2.1875rem;
}
.van_rule{
    padding-bottom: 2.8125rem;
}
</style>